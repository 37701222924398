<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M23.25 7.44v0c0-1.9-1.55-3.44-3.44-3.44H4.19l-.01 0C2.28 4 .74 5.54.74 7.44v9.12l0 0c0 1.89 1.54 3.43 3.44 3.43H19.8l-.01-.001c1.89 0 3.44-1.55 3.44-3.44ZM9.5 15.94V7.28l6.77 4.33Z"
    />
  </svg>
</template>
